var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BlockTitle", {
        staticClass: "msg-title",
        attrs: { title: "上下班时间设置", "is-bold": true },
      }),
      _vm._l(_vm.form.shiftPunchRuleList, function (item, index) {
        return _c("div", { key: index, staticClass: "time-card" }, [
          _vm.form.shiftType === "A2"
            ? _c("div", { staticClass: "time-title flex-just-between" }, [
                _c("span", [_vm._v(_vm._s("第 " + (index + 1) + "次上下班"))]),
                index
                  ? _c(
                      "svg",
                      {
                        staticClass: "iconpark-icon deleteBtn",
                        on: {
                          click: function ($event) {
                            return _vm.del(index)
                          },
                        },
                      },
                      [_c("use", { attrs: { href: "#delete-one" } })]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "time-content" },
            [
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("上下班时段"),
                    ]),
                    _c("span", { staticClass: "rule" }, [
                      _vm._v("企业规定的上班时间、下班时间"),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("有效打卡时段"),
                    ]),
                    _c("span", { staticClass: "rule" }, [
                      _vm._v(
                        "用于判断员工的打卡是否有效，如凌晨2点打卡是否有效"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "time-box" },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          staticClass: "timepicker",
                          attrs: { label: "上班时间" },
                        },
                        [
                          _c("CbTimePicker", {
                            attrs: {
                              "default-value": "00:00",
                              "end-disabled": item.breakStartTime,
                              options: _vm.timeSetUp,
                            },
                            on: { change: _vm.change },
                            model: {
                              value: item.startTime,
                              callback: function ($$v) {
                                _vm.$set(item, "startTime", $$v)
                              },
                              expression: "item.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.shiftType === "A1"
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: "休息时段" } },
                            [
                              _c("CbTimePicker", {
                                attrs: {
                                  "parent-disabled": !item.startTime,
                                  "default-value": item.startTime,
                                  "start-disabled": item.startTime,
                                  "end-disabled": item.endTime,
                                  options: _vm.timeSetUp,
                                },
                                on: { change: _vm.change },
                                model: {
                                  value: item.breakStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(item, "breakStartTime", $$v)
                                  },
                                  expression: "item.breakStartTime",
                                },
                              }),
                              _c("a-icon", {
                                staticClass: "swap-right",
                                attrs: { type: "swap-right" },
                              }),
                              _c("CbTimePicker", {
                                attrs: {
                                  "parent-disabled": !item.breakStartTime,
                                  "start-disabled": item.breakStartTime,
                                  "end-disabled": item.endTime,
                                  options: _vm.timeSetUp,
                                },
                                on: { change: _vm.change },
                                model: {
                                  value: item.breakEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(item, "breakEndTime", $$v)
                                  },
                                  expression: "item.breakEndTime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          staticClass: "timepicker",
                          attrs: { label: "下班时间" },
                        },
                        [
                          _c("CbTimePicker", {
                            attrs: {
                              "parent-disabled":
                                _vm.form.shiftType === "A1"
                                  ? !item.breakEndTime
                                  : !item.startTime,
                              options: _vm.timeSetUp,
                              "start-disabled":
                                _vm.form.shiftType === "A1"
                                  ? item.breakEndTime
                                  : item.startTime,
                            },
                            on: { change: _vm.change },
                            model: {
                              value: item.endTime,
                              callback: function ($$v) {
                                _vm.$set(item, "endTime", $$v)
                              },
                              expression: "item.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "上班打卡时段" } },
                        [
                          _c("CbTimePicker", {
                            attrs: {
                              "parent-disabled": !item.endTime,
                              "end-disabled": item.endTime,
                              options: _vm.timeSetUp,
                            },
                            model: {
                              value: item.punchInStartTime,
                              callback: function ($$v) {
                                _vm.$set(item, "punchInStartTime", $$v)
                              },
                              expression: "item.punchInStartTime",
                            },
                          }),
                          _c("a-icon", {
                            staticClass: "swap-right",
                            attrs: { type: "swap-right" },
                          }),
                          _c("CbTimePicker", {
                            attrs: {
                              "parent-disabled": !item.punchInStartTime,
                              "start-disabled": item.punchInStartTime,
                              "end-disabled":
                                _vm.form.shiftType === "A1"
                                  ? item.breakEndTime
                                  : item.endTime,
                              options: _vm.timeSetUp,
                            },
                            model: {
                              value: item.punchInEndTime,
                              callback: function ($$v) {
                                _vm.$set(item, "punchInEndTime", $$v)
                              },
                              expression: "item.punchInEndTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "下班打卡时段" } },
                        [
                          _c("CbTimePicker", {
                            attrs: {
                              "parent-disabled": !item.punchInEndTime,
                              "start-disabled":
                                _vm.form.shiftType === "A1"
                                  ? item.breakStartTime
                                  : item.punchInEndTime,
                              options: _vm.timeSetUp,
                            },
                            model: {
                              value: item.punchOutStartTime,
                              callback: function ($$v) {
                                _vm.$set(item, "punchOutStartTime", $$v)
                              },
                              expression: "item.punchOutStartTime",
                            },
                          }),
                          _c("a-icon", {
                            staticClass: "swap-right",
                            attrs: { type: "swap-right" },
                          }),
                          _c("CbTimePicker", {
                            attrs: {
                              "parent-disabled": !item.punchOutStartTime,
                              "start-disabled": item.punchOutStartTime,
                              options: _vm.timeSetUp,
                            },
                            model: {
                              value: item.punchOutEndTime,
                              callback: function ($$v) {
                                _vm.$set(item, "punchOutEndTime", $$v)
                              },
                              expression: "item.punchOutEndTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "time-bottom" },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "未打卡为" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: item.invalidPunchHandleType,
                            callback: function ($$v) {
                              _vm.$set(item, "invalidPunchHandleType", $$v)
                            },
                            expression: "item.invalidPunchHandleType",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v("缺卡"),
                          ]),
                          _c("a-radio", { attrs: { value: "2" } }, [
                            _vm._v("旷工"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      }),
      _c(
        "div",
        { staticClass: "time-add" },
        [
          _vm.form.shiftType === "A2"
            ? _c(
                "a-button",
                {
                  attrs: { type: "link", prefix: "add-one" },
                  on: {
                    click: function ($event) {
                      return _vm.form.shiftPunchRuleList.push({
                        invalidPunchHandleType: "1",
                        breakFlag: false,
                      })
                    },
                  },
                },
                [_vm._v(" 添加上下班时间 ")]
              )
            : _vm._e(),
          _vm.form.shiftHours
            ? _c("p", [
                _vm._v("合计工作时长："),
                _c("span", [_vm._v(_vm._s(_vm.form.shiftHours))]),
                _vm._v("小时"),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }